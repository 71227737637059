import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_audit_logs_filter = _resolveComponent("audit-logs-filter")!
  const _component_audit_logs_table = _resolveComponent("audit-logs-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_audit_logs_filter, {
        expand: _ctx.expand,
        "onUpdate:expand": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expand) = $event)),
        "hide-search": ""
      }, null, 8, ["expand"]),
      _createVNode(_component_audit_logs_table, {
        headers: _ctx.tableHeaders,
        items: _ctx.tableItems,
        expand: _ctx.expand,
        "items-name": "logs",
        "hide-actions": ""
      }, null, 8, ["headers", "items", "expand"])
    ]),
    _: 3
  }))
}