
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import useReportingOpenModal from '@/compositions/reporting/useReportingOpenModal'
import { formatDate } from '@/services/dateTimeService'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'AuditLogsTable',
  components: { TmButton, TmPerson, TmStatus, TmTable },
  setup() {
    const { openModalEventSummary } = useReportingOpenModal()

    return {
      formatDate,
      openModalEventSummary,
    }
  },
})
