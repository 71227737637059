import { useModals } from '@/compositions/modals'
import type { ReportingAuditLogs } from '@/definitions/reporting/types'

type UseComposeOpenModal = () => {
  openModalEventSummary: (item: ReportingAuditLogs) => void
}

const useReportingOpenModal: UseComposeOpenModal = () => {
  const { openModal } = useModals()

  const openModalEventSummary = (item: ReportingAuditLogs) => {
    openModal('eventSummaryAuditLogs', {
      modalTitle: 'Event summary',
      item,
    })
  }

  return {
    openModalEventSummary,
  }
}

export default useReportingOpenModal
