import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58f0aca0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-weight-normal font-color-inherit blue-on-hover" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "neutral--text blue-on-hover" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, { class: "audit-logs-table" }, {
    "body-cell-date-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_button, {
        "text-link": "",
        onClick: ($event: any) => (_ctx.openModalEventSummary(row))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.formatDate(row.date, 'D MMM YYYY, h:mm:ss')), 1)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    "body-cell-account-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_person, {
        name: row.account.fullName,
        "avatar-url": row.account.avatar,
        "avatar-color": row.account.avatarColor,
        "avatar-size": "small"
      }, null, 8, ["name", "avatar-url", "avatar-color"])
    ]),
    "body-cell-category-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(row.category)), null, 16)
    ]),
    "body-cell-action-slot": _withCtx(({ row }) => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_tm_button, {
          class: "mr-6px",
          "text-link": "",
          onClick: ($event: any) => (_ctx.openModalEventSummary(row))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              name: "public",
              class: "distinct--text mr-2"
            }),
            _createElementVNode("span", _hoisted_3, _toDisplayString(row.action.name), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createElementVNode("span", {
          class: "light--text",
          innerHTML: row.action.description
        }, null, 8, _hoisted_4)
      ])
    ]),
    _: 1
  }))
}