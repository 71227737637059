
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import AuditLogsFilter from '@/components/pages/reporting/auditLogs/AuditLogsFilter.vue'
import AuditLogsTable from '@/components/pages/reporting/auditLogs/AuditLogsTable.vue'
import type { ReportingAuditLogs } from '@/definitions/reporting/types'

export default defineComponent({
  components: {
    AuditLogsTable,
    AuditLogsFilter,
    PageContent,
  },
  setup() {
    const expand = ref(false)
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Date', value: 'date-slot', width: '20%' },
      { text: 'Account', value: 'account-slot', width: '20%' },
      { text: 'Category', value: 'category-slot', width: '20%' },
      { text: 'Action', value: 'action-slot', width: '40%', expandPossible: true },
    ])
    const tableItems = ref<ReportingAuditLogs[]>(getTableData('reportingAuditLogs'))

    return {
      expand,
      tableHeaders,
      tableItems,
    }
  },
})
